import Button from "@material-ui/core/Button"
import { graphql, useStaticQuery } from "gatsby"
import { getSrc, getSrcSet } from "gatsby-plugin-image"
import React, { useRef } from "react"
import { Parallax } from "react-parallax"
import ContactForm from "../components/contact"
import Layout from "../components/layout"
import Seo from "../components/seo"

const scrollToRef = ref =>
  window.scrollTo({
    top: ref.current.offsetTop,
    left: 0,
    behavior: "smooth",
  })
const IndexPage = () => {
  const { bgImage1, bgImage2 } = useStaticQuery(graphql`
    query {
      bgImage1: file(name: { eq: "bg3" }) {
        childImageSharp {
          gatsbyImageData(width: 3840)
        }
      }
      bgImage2: file(name: { eq: "bg4" }) {
        childImageSharp {
          gatsbyImageData(width: 3840)
        }
      }
    }
  `)
  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)

  const srcSetBgImage1 = getSrcSet(bgImage1)
  const srcBgImage1 = getSrc(bgImage1)
  const srcSetBgImage2 = getSrcSet(bgImage2)
  const srcBgImage2 = getSrc(bgImage2)

  return (
    <Layout>
      <Seo title="Home" />
      <Parallax
        blur={0}
        bgImage={srcBgImage1}
        bgImageSrcSet={`${srcSetBgImage1}`}
        bgImageAlt="background-hero-image"
        strength={300}
        bgImageStyle={{ minWidth: "1920px" }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            color: "white",
            backgroundPosition: "center",
            minHeight: "450px",
            justifyContent: "center",
          }}
        >
          <h1
            style={{
              textAlign: "center",
              color: "black",
              background: "rgba(255,255,255,0.7)",
              padding: "8px",
              borderRadius: "6px",
            }}
          >
            Wir entwickeln Immobilien und schaffen Werte
          </h1>
          <Button
            variant="contained"
            size="large"
            style={{ backgroundColor: "#d3c7a1" }}
            onClick={executeScroll}
          >
            Nehmen Sie jetzt kontakt auf!
          </Button>
        </div>
      </Parallax>
      <div
        style={{
          display: "flex",
          maxWidth: "960px",
          margin: "40px auto",
          flexDirection: "column",
          padding: "1.45rem 1.0875rem",
        }}
      >
        <h1 style={{ alignSelf: "center", textAlign: "center" }}>
          Juno Living
        </h1>
        <p style={{ textAlign: "center" }}>
          Wir sind ein mittelständischer Immobilienentwickler von Wohnimmobilien
          im Rhein-Ruhr-Gebiet. Unser Fokus liegt auf der Sanierung und
          Modernisierung von Bestandsimmobilien. Hierbei legen wir besonderen
          Wert auf eine qualitativ hochwertige Ausführung sämtlicher Gewerke.
          Unser Netzwerk besteht aus den besten Handwerksunternehmen der Region.
          Die Zufriedenheit unserer Kunden ist für uns oberstes Gebot; nicht
          Gewinnmaximierung.
        </p>
      </div>

      <Parallax
        blur={0}
        bgImage={srcBgImage2}
        bgImageSrcSet={`${srcSetBgImage2}`}
        bgImageAlt="background-hero-image-2"
        strength={300}
        bgImageStyle={{ minWidth: "1920px" }}
        bgClassName="filterbrightness"
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            color: "white",
            backgroundPosition: "center",
            minHeight: "450px",
            justifyContent: "center",
            padding: "16px",
          }}
        >
          <h1 style={{ textAlign: "center" }}>
            Sie sind auf der Suche nach Ihrer Traumimmobilie – egal ob als
            Selbstnutzer oder Kapitalanleger?
          </h1>
          <h2 style={{ textAlign: "center" }}>
            Dann hinterlassen Sie Ihre Emailadresse und wir schicken Ihnen
            regelmäßig unsere neuesten Objekte zu.
          </h2>
        </div>
      </Parallax>
      <div
        ref={myRef}
        style={{
          display: "flex",
          maxWidth: "460px",
          margin: "40px auto",
          flexDirection: "column",
        }}
      >
        <ContactForm />
      </div>

      <div
        style={{
          display: "flex",
          maxWidth: "960px",
          margin: "40px auto",
          flexDirection: "column",
        }}
      >
        <h3 style={{ alignSelf: "center" }}>Impressum</h3>
        <div className="impressum">
          <p>
            Juno Living GmbH
            <br />
            Im Teelbruch 104 <br />
            45219 Essen
          </p>
          <p>
            AG Düsseldorf HRB 89571
            <br />
            Geschäftsführerin: <br />
            Linda Schütz
            <br />
            Telefon <a href="tel:+4920549693411">(02054) 9693-411</a>
          </p>
          <p>
            <a href="mailto:info@juno-living.de">info@juno-living.de</a>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
